<template>
  <div class="scoringSystem">
    <el-radio-group @change="tabChange" style="margin-bottom:15px;" v-model="radio1">
      <el-radio-button v-if="roleType" label="发布任务"></el-radio-button>
      <el-radio-button label="任务详情"></el-radio-button>
      <el-radio-button label="评分"></el-radio-button>
    </el-radio-group>

    <div v-if="publicTaskShow">
      <div class="flex-box">
        <div class="left">
          <el-button @click="addTask" type="primary">新增任务</el-button>
          <!-- <el-button type="info">导出</el-button> -->
        </div>
        <div class="flex-box">
          <el-date-picker v-model="query.queryTime"
                type="daterange"
                class="header-query-frame"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
          </el-date-picker>

          <!-- <el-date-picker
            v-model="query.start_time"
            type="date"
            placeholder="请选择开始时间">
          </el-date-picker>
          <el-date-picker
            class="picker"
            v-model="query.end_time"
            type="date"
            placeholder="请选择结束时间">
          </el-date-picker> -->
          <el-input
            placeholder="请输入教师姓名"
            class="picker"
            v-model="query.name"
          ></el-input>
          <el-button @click="queryTask(1)" type="primary" class="picker" 
            >查 询</el-button
          >
        </div>
      </div>

      <el-table :data="taskList">
        <el-table-column
          label="任务名称"
          prop="name"
          key="name1"
          align="center"
        ></el-table-column>
        <el-table-column
          label="被评分教师"
          prop="teacherNames"
          key="teacherNames1"
          align="center"
        ></el-table-column>
        <el-table-column
          label="评分教师"
          prop="rater_teacherNames"
          key="rater_teacherNames1"
          align="center"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="begin_time"
          key="begin_time1"
          align="center"
        ></el-table-column>
        <el-table-column
          label="结束时间"
          prop="end_time"
          key="end_time1"
          align="center"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="statusStr"
          key="statusStr1"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" width="270px" prop="course" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="taskDetail(scope.row)"
              >详情
            </el-button>
            <el-button size="mini" type="success" @click="taskEdit(scope.row)"
              >编辑
            </el-button>
            <el-button size="mini" type="danger" @click="taskDelete(scope.row.id)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev,pager,next"
        background
        :page-count="page.pageCount"
        :page-size="page.currentPage"
        @current-change="queryTask"
      ></el-pagination>

      <el-dialog
        :title="addTaskForm.id ? '修改' : '添加'"
        :visible.sync="addTaskDialogVisible"
        width="600px"
      >
        <div class="center">
          <el-form
            :model="addTaskForm"
            ref="addTaskFormRef"
            label-width="120px"
            :rules="addTaskFormRules"
          >
            <el-form-item label="任务名称：" prop="name">
              <el-input
                type="text"
                placeholder="请输入任务名称"
                v-model="addTaskForm.name"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否上传资料：" prop="type">
              <el-radio v-model="addTaskForm.type" :label="2">是</el-radio>
              <el-radio v-model="addTaskForm.type" :label="1">否</el-radio>
            </el-form-item>

            <el-form-item label="被评分教师：" prop="teacherIds">
              <el-select v-model="addTaskForm.teacherIds" multiple placeholder="请选择被评分教师">
                <el-option
                  v-for="item in allTeacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="评分教师：" prop="rater_teacherIds">
              <el-select v-model="addTaskForm.rater_teacherIds" multiple placeholder="请选择评分教师">
                <el-option
                  v-for="item in allTeacherList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="开始时间：" prop="begin_time">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="addTaskForm.begin_time"
                type="date"
                placeholder="请选择开始时间">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="结束时间：" prop="end_time">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="addTaskForm.end_time"
                type="date"
                placeholder="请选择结束时间">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <!-- 确认与取消 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addTaskDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="taskConfirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div v-if="taskDetailShow">
      <el-table :data="taskDetailList">
        <el-table-column
          label="任务名称"
          prop="name"
          align="center"
          key="name2"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="begin_time"
          align="center"
          key="begin_time2"
        ></el-table-column>
        <el-table-column
          label="结束时间"
          prop="end_time"
          align="center"
          key="end_time2"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="statusStr"
          align="center"
          key="statusStr2"
        ></el-table-column>

        <el-table-column label="操作" width="270px" prop="course" align="center">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.status === 1" type="primary" @click="uploadTaskByTea(scope.row)"
              >上传
            </el-button>
            <el-button size="mini" v-if="scope.row.status === 2" type="success" @click="taskEditByTea(scope.row)"
              >编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="上传"
        :visible.sync="uploadTaskDialogVisible"
        width="600px"
      >
        <div class="center">
          <el-form
            :model="uploadTaskForm"
            ref="uploadTaskFormRef"
            label-width="120px"
            :rules="uploadTaskFormRules"
          >
            <el-form-item label="附件：" prop="url">
              <el-upload
                class="upload-demo"
                action="/api/base/uploadByOss.do"
                :headers="{Authorization:token}"
                :limit="1"
                :on-remove="onRemoveFile"
                ref="upload"
                :on-success="enclosureUploadSuccess"
                :data="{path:'scoringSystem',name:''}"
                :show-file-list="true">

                <el-button size="small" type="primary">点击上传</el-button>
                <div v-if="uploadTaskForm.url" slot="tip" class="el-upload__tip">资料已上传，可下载附件，也可重新上传</div>
              </el-upload>
              <el-button type="primary"
                      class="load-file"
                      @click="loadFile"
                      size="small"
                      v-if="uploadTaskForm.url">下载附件</el-button>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                v-model="uploadTaskForm.remark"
              ></el-input>
            </el-form-item>

          </el-form>
        </div>

        <!-- 确认与取消 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="uploadTaskDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="uploadTaskConfirm">确 定</el-button>
        </span>
      </el-dialog>

    </div>

    <div v-if="scoreShow">
      <el-table :data="queryScoringSystemByRaterList">
        <el-table-column
          label="任务名称"
          prop="name"
          align="center"
          key="name3"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="begin_time"
          align="center"
          key="begin_time3"
        ></el-table-column>
        <el-table-column
          label="结束时间"
          prop="end_time"
          align="center"
          key="end_time3"
        ></el-table-column>
        <el-table-column label="操作" width="270px" prop="course" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="toScoreDetail(scope.row)" type="primary"
              >详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      
    </div>

  </div>
</template>

<script>
import classSelector from "@/components/selectors/classSelector";
import termSelector from "@/components/selectors/termSelector";

export default {
  components: { classSelector, termSelector },
  name: "scoringSystem",
  data() {
    return {
      token: sessionStorage.getItem("token"),
      page: { pageCount: 1, currentPage: 0 },
      radio1:'发布任务',
      // 查询
      query:{
        queryTime:'',
        // start_time:'',
        // end_time:'',
        name:''
      },
      // 新增任务对话框显示与隐藏
      addTaskDialogVisible:false,
      // 新增任务表单
      addTaskForm:{
        id:'',
        teacherIds:'',
        rater_teacherIds:'',
        name:'',
        type:2,
        // standard:'',
        begin_time:'',
        end_time:''
      },
      // 新增任务表单验证规则对象
      addTaskFormRules:{
        teacherIds:[{required:true,message:'请选择被评分教师',trigger:'blur'}],
        rater_teacherIds:[{required:true,message:'请选择评分教师',trigger:'blur'}],
        name:[{required:true,message:'请输入任务名称',trigger:'blur'}],
        type:[{required:true,message:'请选择是否上传资料',trigger:'blur'}],
        begin_time:[{required:true,message:'请选择开始时间',trigger:'blur'}],
        end_time:[{required:true,message:'请选择结束时间',trigger:'blur'}],
      },
      // 所有教师列表
      allTeacherList:[],
      // 模块管理员列表
      moduleManagementList:JSON.parse(sessionStorage.getItem('userInfo')).nav1,
      // 是否为模块管理员
      roleType:false,
      // 当前学期
      nowSemester:JSON.parse(sessionStorage.getItem('nowSemester')),
      // 任务列表
      taskList:[],
      // 教师详情列表
      teacherDetailList:[],

      // 被评分人任务详情列表
      taskDetailList:[],
      // 被评分人任务详情表单
      uploadTaskForm:{
        id:'',
        url:'',
        remark:''
      },
      uploadTaskFormRules:{
        url:[{required:true}]
      },
      // 上传任务对话框显示与隐藏
      uploadTaskDialogVisible:false,

      // taskDetailShow:false,
      // publicTaskShow:true,
      // scoreShow:false,

      taskDetailShow:'',
      publicTaskShow:'',
      scoreShow:'',
      // 评分任务列表
      queryScoringSystemByRaterList:[],
      uploadType:'',
      

    };
  },
  created() {
    // 循环模块管理员列表
    for(let i = 0; i < this.moduleManagementList.length; i++){
      console.log(1)
      if(this.moduleManagementList[i].id===1303){
        // 模块管理员(主管)
        this.roleType = true
        // break
      }
    }
    console.log(this.radio1)
    console.log(this.roleType)

    if(this.roleType){
      this.taskDetailShow = false
      this.publicTaskShow = true
      this.scoreShow = false
      // 查询教师列表
      this.queryTeacherList()
      this.queryTask(1)
    }else{
      this.radio1 = '任务详情'
      
      this.taskDetailShow = true
      this.publicTaskShow = false
      this.scoreShow = false
      // if(this.taskDetailList.length == 0){
      //   this.radio1 = '评分'
      // }else{
      //   this.radio1 = '任务详情'
      // }
    }

    // if(this.radio1 == '发布任务'){
    //   if(this.roleType){
    //     this.taskDetailShow = false
    //     this.publicTaskShow = true
    //     this.scoreShow = false
    //     // 查询教师列表
    //     this.queryTeacherList()
    //     this.queryTask(1)
    //   }
    // }
    
  },
  methods: {
    enclosureUploadSuccess(res, file) {
      this.uploadTaskForm.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.uploadTaskForm.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.uploadTaskForm.url = "";
    },
    tabChange(value){
      console.log(value)
      if(value == '任务详情'){
        this.taskDetailShow = true
        this.publicTaskShow = false
        this.scoreShow = false
        this.queryScoringSystemByTea()
      }else if(value == '发布任务'){
        this.taskDetailShow = false
        this.publicTaskShow = true
        this.scoreShow = false
        this.queryTeacherList()
        this.queryTask(1)
      }else if(value == '评分'){
        this.taskDetailShow = false
        this.publicTaskShow = false
        this.scoreShow = true
        this.queryScoringSystemByRater()
      }
    },
    // 评分人查询任务
    queryScoringSystemByRater(){
      this.$post(`/base/queryScoringSystemByRater.do`,{
        // yearid:this.nowSemester,
        // begin_time:this.query.start_time,
        // end_time:this.query.end_time
      })
      .then(res=>{
        console.log(res.data)
        this.queryScoringSystemByRaterList = res.data
      })
    },
    // 跳转到评分详情
    toScoreDetail(row){
      console.log(row)
      // return
      this.$router.push({
        name:'scoreDetail',
        query:{
          score_id:row.score_id,
          rater_id:row.id,
          begin_time:row.begin_time,
          end_time:row.end_time,
        }
      })
    },
    // 被评分人查询任务
    queryScoringSystemByTea(){
      this.$post(`/base/queryScoringSystemByTea.do`)
      .then(res=>{
        console.log(res)
        this.taskDetailList = res.data
      })
    },
    // 被评分人上传任务
    uploadTaskByTea(row){
      this.uploadType = 1
      if(this.getTime(row.begin_time,row.end_time)){
        this.uploadTaskDialogVisible = true
        this.$nextTick(()=>{
          this.$refs.upload.clearFiles();
          this.uploadTaskForm.id = row.id
          this.uploadTaskForm.url = ''
          this.uploadTaskForm.remark = ''
        })
      }else{
        this.$message({
          type:'info',
          message:'当前时间不在任务时间范围内！'
        })
      }
      
    },
    // 判断当天时间是否属于评分时间范围内
    getTime(startTime,endTime){
      // 当天时间
      let currentTime = new Date().getTime()
      let begin_time = new Date(startTime).getTime()
      let end_time = new Date(endTime).getTime()
      if(currentTime >= begin_time && currentTime <= end_time + 86400000){
        return true
      }else{
        return false
      }

    },
    uploadTaskConfirm(){
      console.log(this.uploadTaskForm)
      this.$refs.uploadTaskFormRef.validate(validate=>{
        if(validate){
          this.$post(`/base/updateScoringSystemByTea.do`,{
            id:this.uploadTaskForm.id,
            url:this.uploadTaskForm.url,
            remark:this.uploadTaskForm.remark,
          }).then(res=>{
            console.log(res)
            this.uploadTaskDialogVisible = false
            if(this.uploadType === 1){
              this.$message({
                type:'success',
                message:'上传成功！'
              })
            }else{
              this.$message({
                type:'success',
                message:'编辑成功！'
              })
            }
            this.queryScoringSystemByTea()
          }).catch(err=>{
            this.$message({
              type:'error',
              message:err.message
            })
          })
        }
      })
    },
    // 被评分人编辑任务
    taskEditByTea(row){
      this.uploadType = 2
      this.uploadTaskDialogVisible = true
      this.$nextTick(()=>{
        this.uploadTaskForm.id = row.id
        this.uploadTaskForm.remark = row.remark
        this.uploadTaskForm.url = row.url
        this.$refs.upload.clearFiles();
      })
      
    },
    // 查询教师列表
    queryTeacherList(){
      this.$post(`/media/queryAllManager.do`,{
        is_no:1
      })
      .then(res=>{
        // console.log(res)
        this.allTeacherList = res.data
      })
    },
    // 查询任务
    queryTask(page){
      this.$post(`/base/queryScoringSystem.do`,{
        indexNo:page,
        yearid:this.nowSemester,
        begin_time:this.query.queryTime[0],
        end_time:this.query.queryTime[1],
        // begin_time:this.query.start_time,
        // end_time:this.query.end_time,
        name:this.query.name,
      }).then(res=>{
        // console.log(res)
        this.page.currentPage = res.data.indexNO
        this.page.pageCount = res.data.pageCount
        this.taskList = res.data.rows
        
      })
    },
    // 新增任务
    addTask(){
      this.addTaskDialogVisible = true
      this.$nextTick(()=>{
        this.$refs.addTaskFormRef.resetFields()
        this.addTaskForm.id = ''
      })

    },
    // 编辑
    taskEdit(row){
      this.addTaskDialogVisible = true
      this.$nextTick(()=>{
        this.addTaskForm.id = row.id
        this.addTaskForm.teacherIds = row.teacherIds.split(',').map(Number)
        this.addTaskForm.rater_teacherIds = row.rater_teacherIds.split(',').map(Number)
        this.addTaskForm.name = row.name
        this.addTaskForm.begin_time = row.begin_time
        this.addTaskForm.end_time = row.end_time
      })

    },
    // 任务提交
    taskConfirm(){
      this.$refs.addTaskFormRef.validate(validate=>{
        if(validate){
          let data = {
            teacherIds:this.addTaskForm.teacherIds.join(','),
            rater_teacherIds:this.addTaskForm.rater_teacherIds.join(','),
            name:this.addTaskForm.name,
            begin_time:this.addTaskForm.begin_time,
            end_time:this.addTaskForm.end_time,
            id:this.addTaskForm.id,
            type:this.addTaskForm.type,
          }
          console.log(data)
          if(this.addTaskForm.id){
            this.$post(`/base/updateScoringSystem.do`,data)
            .then(res=>{
              this.$message({
                type:'success',
                message:'编辑成功！'
              })
              this.addTaskDialogVisible = false
              this.queryTask(this.page.currentPage)
            })
            return
          }
          // return
          this.$post(`/base/insertScoringSystem.do`,data)
          .then(res=>{
            // console.log(res)
            this.$message({
              type:'success',
              message:'添加成功！'
            })
            this.addTaskDialogVisible = false
            this.queryTask(1)
          })

        }
      })
    },
    // 删除
    taskDelete(id) {
      this.$confirm("是否删除该条记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteScoringSystem.do", { id })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.queryTask(this.page.currentPage);
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 详情
    taskDetail(row){
      this.$router.push({
        name:'publishTaskDetails',
        query:{
          score_id:row.id
        }
      })

      // this.$post(`/base/queryScoringSystemDetail.do`,{score_id:row.id})
      // .then(res=>{
      //   console.log(res)
      //   this.teacherDetailList = res.data
      // })
    },




    // 时间格式
    timeFormat(time){
      let t = new Date(time)
      let year = t.getFullYear()
      let month = (t.getMonth()+1) < 10 ?  '0' + (t.getMonth()+1) : (t.getMonth()+1)
      let date = t.getDate() < 10 ? '0' + t.getDate() : t.getDate()
      let hour = t.getHours() < 10 ? '0' + t.getHours() : t.getHours()
      let mimute = t.getMinutes() < 10 ? '0' + t.getMinutes() : t.getMinutes()
      return `${year}-${month}-${date} ${hour}:${mimute}`
    },
    
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>
